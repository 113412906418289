// Helper function to mimic blade components.
// Export function so I can use in other files.

// Function to make js equivalent of show card blade component.
export function show_card_js(labelName, value) {
    let showCard = $(`
    <div class="row ml-0">
        <div class="show-card d-block">
            <div class="show-card-content">
                <label class="d-block show-card-label">${labelName}</label>
                <p class="d-block show-card-text">${value}</p>
            </div>
        </div>
    </div>
    `)

    return showCard;
}

export function bs_input_textarea_js(labelName, name, tooltip)
{
    let textarea = $(`
    <div class="form-group col pl-0">
        <label for="${name}">${labelName} <i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top" title="${tooltip}"></i></label>
        <textarea class='form-control' name="${name}" type='text'></textarea>
    </div>`)

    return textarea;
}

export function bs_input_dropdown_js(labelName, name, tooltip, options, multiple = false, selected = [])
{
    let dropdown = $(`
    <div class="form-group col pl-0">
        <label for="${name}">${labelName} <i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top" title="${tooltip}"></i></label>
    </div>
    `);

    let select = $(`<select ${multiple === true ? "multiple" : ""} class="form-control" name="${name}"></select>`);

    console.log(options);

    // Loop through array of objects.
    for (let i = 0; i < options.length; i++)
    {
        let option = $(`<option ${selected.includes(options[i].key) ? "selected" : "" } value="${options[i].key}">${options[i].value}</option>`);
        select.append(option);
    }

    dropdown.append(select);

    return dropdown;
}
