// Ajax call to get customers
jQuery(function() {
    let dropdown = $("#main-customer-dropdown");

    let select2 = $('#main-customer-dropdown').select2({
        theme: 'bootstrap4',
    });

    select2.data('select2').$container.addClass("customer-select2")

    $.ajax({
        url: "/api/v1/customers?sort=name",
        type: 'GET',
        headers: {
            "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response) {

            let customers = response["data"];
            let currentCustomer = dropdown.data("current-customer");

            for (let i = 0; i < customers.length; i++)
            {
                let customer = customers[i];
                let option = $("<option></option>");

                option.attr("value", customer["id"]);
                option.text(customer["name"]);

                if (customer["id"] == currentCustomer)
                {
                    option.attr("selected", true);
                }

                dropdown.append(option);
            }

            // Show form after ajax is done.
            $('#select-customer-form').removeClass('invisible');
        },
        error: function(error) {
            console.log(error);
        }
    });

    dropdown.on("change", function(e) {
        console.log($(this).val());
        let form = $(this).closest('form');
        form.trigger("submit");
    });
});
