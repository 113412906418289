window['getProductTypePartial'] = async function getProductTypePartial(selectedValue, order = null, orderEntry = null)
{
    let orderTypeMapping = {
        nbn : `/admin/partials/extra_fields/nbn/create/${selectedValue}`,
        exchange_mailbox : `/admin/partials/extra_fields/exchange_mailbox/create/${selectedValue}`,
    };

    let editOrderTypeMapping = {
        nbn : `/admin/partials/extra_fields/nbn/edit/${order}/${selectedValue}`,
        exchange_mailbox : `/admin/partials/extra_fields/exchange_mailbox/edit/${order}/${selectedValue}`,
    };

    // Ajax call to get a type name from a product id.
    let response = await getProduct(selectedValue);
    let typeName = response.data.attributes.type.name;

    let url = orderTypeMapping[typeName];

    console.log('Order id is ' + order);
    console.log('Type name is ' + typeName);

    if (order != null)
    {
        url = editOrderTypeMapping[typeName];
    }


    console.log("getProductTypePartial Order Entry: " + orderEntry);

    if (orderEntry != null)
    {
        url += '?order_entry=' + orderEntry;
    }

    console.log(url);

    return url;
}

function getProduct(selectedValue)
{
    return new Promise(resolve => {
        $.ajax({
            url: `/api/v1/products/${selectedValue}?include=type`,
            type: 'GET',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            success: function(data) {
                resolve(data);
            },
            error: function(error) {
                console.log(error);
            }
        });
      });
}

window['getOrderTypePartial'] = function getOrderTypePartial(selectedValue, orderId = null, id = null)
{
    let orderTypeMapping = {
        add : "/admin/partials/order_forms/add/create",
        upgrade : "/admin/partials/order_forms._upgrade",
        downgrade : "/admin/partials/order_forms._downgrade",
        cancel : "/admin/partials/order_forms._cancel",
    };

    let editOrderTypeMapping = {
        add : `/admin/partials/order_forms/add/edit/${orderId}`,
        upgrade : "/admin/partials/order_forms._upgrade",
        downgrade : "/admin/partials/order_forms._downgrade",
        cancel : "/admin/partials/order_forms._cancel",
    };

    let url = orderTypeMapping[selectedValue];

    if (orderId != null)
    {
        url = editOrderTypeMapping[selectedValue];
    }

    if (id != null)
    {
        url += '?id=' + id;
    }

    console.log("getOrderTypePartial Id: " + id);
    console.log(url);

    return url;
}

class LoadPartial extends HTMLElement { // (1)

    connectedCallback() {

        //For demo, display src as the partial. In reality, use AJAX to fetch HTML.
        let srcCallback = this.getAttribute('src-callback') || undefined;
        let target = this.getAttribute('target') || undefined;
        let event = this.getAttribute('event') || undefined;
        let model = this.getAttribute('model') || undefined;
        let id = this.getAttribute('id') || undefined;
        let orderEntry = this.getAttribute('order-entry') || undefined;

        console.log("ORDER ENTRY: " + orderEntry);

        let targetElement = $(target);

        var functPtr = window[srcCallback];
        // some functions are async and return a promise
        var src = null;

        console.log('srcCallback: ' + srcCallback);
        console.log("model is " + model);

        if (srcCallback === 'getProductTypePartial')
        {
            src = functPtr($(target).val(), model, orderEntry);
        }
        else if (srcCallback === 'getOrderTypePartial')
        {
            src = functPtr($(target).val(), model, id);
        }
        else
        {
            console.log('No such callback.')
            return;
        }

        var partialContainer = $(this);

        // To check if the return value is a promise we check for a .then method.
        try
        {
            src.then(url => renderPartial(partialContainer, url));
        }
        catch (error) {
            renderPartial(partialContainer, src);
        }

        // On event
        targetElement.each(function (index) {
            $(this).on(event, function () {
                src = functPtr($(target).val());

                console.log("ADDING EVENT: " + model + " " + orderEntry);

                if (srcCallback === 'getProductTypePartial')
                {
                    src = functPtr($(target).val(), model, orderEntry);
                }
                else if (srcCallback === 'getOrderTypePartial')
                {
                    src = functPtr($(target).val(), model, id);
                }
                else
                {
                    console.log('No such callback.')
                    return;
                }

                try
                {
                    src.then(url => renderPartial(partialContainer, url));
                }
                catch (error) {
                    renderPartial(partialContainer, src);
                }
            });
        });

        // Here we make an ajax request to get the partial based on the selected value of the dropdown
        function renderPartial(container, src) {

            if (src == null)
            {
                console.log("broken")
                return;
            }

            $.ajax({
                url: src,
                type: 'GET',
                headers: {
                    "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                },
                success: function(data) {
                    //let partialObject = $(data);
                    container.html(data);

                    // Initiate select 2 on dropdowns.
                    container.find('.my-select2').select2({
                        theme: 'bootstrap4',
                    });
                },
                error: function(error) {
                    console.log(error);
                }
            });
        }
    }
}

customElements.define("load-partial", LoadPartial); // (2)
