import { show_card_js } from './js_blade_components.js';
import { bs_input_textarea_js } from './js_blade_components.js';

(function ( $ ) {

    var methods = {
        init : function(options)
        {
            let settings = $.extend({
                approvals: null,
                id: null,
                type: null,
                modalId: '#generic-modal',
                toastId: "#main-toast"
            }, options );

            console.log("hi from approvals.js");

            initCheckboxes($(this));

            var table = $(this).DataTable({});
            var container = $(this);

            initButtons($(this), table, settings);

            /*$(this).on('click', 'tr', function(e) {
                // Call approvals.js module and pass approval object or approval id.

                // Get the approval id of what was clicked from the data attribute.
                let approvalId = $(this).data("id");
                let approval = approvals.find(x => x.id == approvalId);

                console.log("click clack: " + approvalId);

                renderModal(container, table, settings, approval);
            });*/
        },
    }

    $.fn.approvals = function(methodOrOptions) {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply( this, Array.prototype.slice.call(arguments, 1));
        }
        else if (typeof methodOrOptions === 'object' || !methodOrOptions)
        {
            // Default to "init"
            return methods.init.apply(this, arguments);
        }
        else
        {
            $.error('Method ' +  methodOrOptions + ' does not exist on jQuery.approvals');
        }
    };

    // Adds a column of checkboxes to the table which will be used for bulk selection.
    function initCheckboxes(container)
    {
        // Insert master checkbox
        let checkboxHeader = $("<th><input type='checkbox' class='check-all'/></th>");
        container.find("thead tr").prepend(checkboxHeader);

        // For each row insert a checkbox
        container.find("tbody tr").each(function() {
            let subCheckbox = $("<input type='checkbox' class='sub-checkbox'/>");

            subCheckbox.attr("name", "selected_ids[]");

            let recordId = $(this).data("id");
            subCheckbox.attr("value", recordId);

            let checkboxCell = $("<td></td>");
            checkboxCell.append(subCheckbox);

            $(this).prepend(checkboxCell);
        })

        // Check/unchecks all sub checkboxes on same page when master checkbox is clicked.
        container.find('.check-all').on('change', function() {
            if (this.checked) {
                $(".sub-checkbox").prop("checked", true);
            } else {
                $(".sub-checkbox").prop("checked", false);
            }
        });

        // Checks/unchecks master checkbox when a sub checkbox is clicked.
        container.find(".sub-checkbox").on('change', function() {
            var numberOfCheckboxes = $(".sub-checkbox").length;
            var numberOfCheckboxesChecked = $('.sub-checkbox:checked').length;
            if(numberOfCheckboxes == numberOfCheckboxesChecked && numberOfCheckboxes != 0) {
                $(".check-all").prop("checked", true);
            } else {
                $(".check-all").prop("checked", false);
            }
        });
    }

    // Function to initialise bulk buttons.
    function initButtons(container, table, settings)
    {
        let mainButtonsContainer = $("<div></div>");
        mainButtonsContainer.addClass("main-buttons-container");

        let bulkAccept = $("<a class='btn btn-success main-btn btn-mr'></a>");
        bulkAccept.text("Bulk Accept/Decline");
        mainButtonsContainer.append(bulkAccept);
        mainButtonsContainer.insertBefore(container);

        $(bulkAccept).on('click', function (e) {
            // Display a warning if no approvals are selected.
            let selectedIds = [];

            table.$('input[type="checkbox"]').each(function() {
                if (this.checked)
                {
                    selectedIds.push(this.value);
                }
            });

            if (selectedIds.length == 0)
            {
                displayToast(settings.toastId, "Atleast one row must be selected.", 3000);
                return;
            }
            else if (selectedIds.length == 1)
            {
                let approvalId = selectedIds[0];
                let approval = approvals.find(x => x.id == approvalId);
                renderModal(container, table, settings, approval);
            }
            else
            {
                renderBulkModal(container, table, settings, selectedIds);
            }
        });
    }

    function renderBulkModal(container, table, settings, selectedIds)
    {
        console.log("Rendering the approval bulk modal");

        let modal = $(settings.modalId);
        let modalBody = modal.find('.modal-body');

        modalBody.empty();
        modal.find('.modal-title').text('Bulk Accept/Decline approvals');

        modalBody.append($(`<p>You have selected ${selectedIds.length} approvals.</p>`));

        // Render input to give reason for accepting/declining an approval.
        let resolutionDescription = $(`
        <div class="form-group col pl-0">
            <label class="show-card-label" for="resolution_description">Reason for accepting/declining <i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top" title="Give a reason"></i></label>
            <textarea class='form-control' name="resolution_description" type='text'></textarea>
        </div>`);
        modalBody.append(resolutionDescription);
        resolutionDescription.find('[data-toggle="tooltip"]').tooltip();

        let modalFooter = modal.find('.modal-footer');
        modalFooter.empty();

        // Add modal button1
        let cancelButton = $(`<button type="button" class="btn btn-secondary waves-effect waves-themed">Cancel</button>`)
        modalFooter.append(cancelButton);

        // Hide the modal on click.
        cancelButton.on('click', function(e) {
            modal.modal('hide');
        });

        // Add decline button
        let declineButton = $(`<button type="button" class="btn btn-danger waves-effect waves-themed">Decline</button>`)
        modalFooter.append(declineButton);

        // Add accept button
        let acceptButton = $(`<button type="button" class="btn btn-success waves-effect waves-themed">Accept</button>`)
        modalFooter.append(acceptButton);

        // Add an event listener on the accept button to send an ajax request.
        acceptButton.on("click", function(e) {
            sendBulkApproval(container, table, settings, selectedIds, $(this), true);
        });

        // Add an event listener on the decline button to send an ajax request.
        declineButton.on("click", function(e) {
            sendBulkApproval(container, table, settings, selectedIds, $(this), false);
        });

        console.log(modal);
        modal.modal('show');
    }

    function renderModal(container, table, settings, approval)
    {
        console.log("Rendering the approval modal");

        let modal = $(settings.modalId);
        let modalBody = modal.find('.modal-body');

        modalBody.empty();
        modal.find('.modal-title').text('Approval Details');

        // Rendering approval details

        let approvalId = show_card_js('Approval Id', approval.id);
        modalBody.append(approvalId);

        let type = show_card_js('Type', approval.type);
        modalBody.append(type);

        let status = show_card_js('Status', approval.status);
        modalBody.append(status);

        let inEffect = show_card_js('In Effect', approval.in_effect);
        modalBody.append(inEffect);

        let description = $(`
        <div class="row ml-0">
            <div class="show-card d-block">
                <div class="show-card-content">
                    <label class="d-block show-card-label">Description</label>
                </div>
            </div>
        </div>
        `);

        let descriptionContent = $(`<ul></ul>`);
        if (approval.description == null || approval.description === '')
        {
            let descriptionPart = $(`<p></p>`);
            descriptionContent.append(descriptionPart);
        }
        else
        {
            let splitted = approval.description.split("\n");

            for (let i = 0; i < splitted.length; i++)
            {
                let descriptionPart = $(`<li>${splitted[i]}</li>`);
                descriptionContent.append(descriptionPart);
            }
        }

        description.find('.show-card-content').append(descriptionContent);
        modalBody.append(description);

        let requestDescription = show_card_js('Request Description', approval.request_description);
        modalBody.append(requestDescription);

        let requestedBy = show_card_js('Requested By', approval.requestor.name);
        modalBody.append(requestedBy);

        // Render input to give reason for accepting/declining an approval.
        let resolutionDescription = $(`
        <div class="form-group col pl-0">
            <label class="show-card-label" for="resolution_description">Reason for accepting/declining <i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top" title="Give a reason"></i></label>
            <textarea class='form-control' name="resolution_description" type='text'></textarea>
        </div>`);
        modalBody.append(resolutionDescription);
        resolutionDescription.find('[data-toggle="tooltip"]').tooltip();

        let modalFooter = modal.find('.modal-footer');
        modalFooter.empty();

        // Add modal button1
        let cancelButton = $(`<button type="button" class="btn btn-secondary waves-effect waves-themed">Cancel</button>`)
        modalFooter.append(cancelButton);

        // Hide the modal on click.
        cancelButton.on('click', function(e) {
            modal.modal('hide');
        });

        // Add decline button
        let declineButton = $(`<button type="button" class="btn btn-danger waves-effect waves-themed">Decline</button>`)
        modalFooter.append(declineButton);

        // Add accept button
        let acceptButton = $(`<button type="button" class="btn btn-success waves-effect waves-themed">Accept</button>`)
        modalFooter.append(acceptButton);

        // Add an event listener on the accept button to send an ajax request.
        acceptButton.on("click", function(e) {
            sendApproval(container, table, settings, approval, $(this), true);
        });

        // Add an event listener on the decline button to send an ajax request.
        declineButton.on("click", function(e) {
            sendApproval(container, table, settings, approval, $(this), false);
        });

        console.log(modal);
        modal.modal('show');
    }

    function sendBulkApproval(container, table, settings, selectedIds, button, approved = false)
    {
        console.log("Sending ajax request to accept/decline approval");
        button.attr("disabled", true);
        button.text('');
        //show spinner
        let spinner = `<div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>`;

        button.append(spinner);

        var modal = $(settings.modalId);
        // Get resolution description.
        let resolutionDescription = modal.find('textarea[name="resolution_description"]').val();

        let url = `/api/v1/approvals/bulk/decline`;

        if (approved === true)
        {
            url = `/api/v1/approvals/bulk/accept`;
        }

        let body = {
            resolution_description: resolutionDescription,
            selected_ids: selectedIds
        };

        $.ajax({
            url: url,
            type: 'PATCH',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            data: body,
            success: function(response) {
                console.log(response);
                // Update the approval entries in the table
                console.log('Updating the approval in the table.')

                let data = response.data;

                for (let i = 0; i < data.length; ++i) {
                    let approvalData = data[i];
                    // Updating the row assumes that the headers are in a consistent order.
                    // This must be changed if table headers change.
                    editRow(container, table, approvalData, settings);
                }

                button.text('');
                button.append($('<i class="fal fa-check"></i>'));
                $('.check-all').prop('checked', false);
                $('.check-all').trigger('change');
            },
            error: function(error) {
                console.log(error);
                button.text('');
                button.append($('<i class="fal fa-times"></i>'));
                let message = error.responseJSON.errors[0].message;
                displayToast(settings.toastId, message, 3000);
            }
        }).done(function () {
            // Close modal with delay, could remove this.
            // Giving user enough time to see the tick.
            setTimeout(function() {
                modal.modal('hide');
            }, 500);
        }).fail(function () {
            setTimeout(function() {
                modal.modal('hide');
            }, 500);
        });
    }

    function editRow(container, table, approvalData, settings)
    {
        let approvalId = approvalData.attributes.id;
        let row = table.row(`[data-id="${approvalId}"]`).data();
        row[3] = approvalData.attributes.status;
        row[4] = approvalData.attributes.in_effect == 1 ? 'yes' : 'no';
        row[5] = approvalData.attributes.description;
        row[8] = approvalData.attributes.resolution_description;
        row[9] = approvalData.attributes.resolver.name;

        table.row(`[data-id="${approvalId}"]`).data(row);

        let displayRow = container.find(`[data-id="${approvalId}"]`);
        var originalColor = displayRow.css("background-color");

        displayRow.delay(500).animate({
            'background-color': '#82c8e8'
        }, 750, function() {
            $(this).animate({
                'background-color': originalColor
            }, 750);
        });
    }

    function sendApproval(container, table, settings, approval, button, approved = false)
    {
        console.log("Sending ajax request to accept/decline approval");
        button.attr("disabled", true);
        button.text('');
        //show spinner
        let spinner = `<div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>`;

        button.append(spinner);

        var modal = $(settings.modalId);
        // Get resolution description.
        let resolutionDescription = modal.find('textarea[name="resolution_description"]').val();

        let url = `/api/v1/approvals/${approval.id}/decline`;

        if (approved === true)
        {
            url = `/api/v1/approvals/${approval.id}/accept`;
        }

        let body = {
            resolution_description: resolutionDescription
        };

        $.ajax({
            url: url,
            type: 'PATCH',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            data: body,
            success: function(response) {
                console.log(response);
                // Update the approval entry in the table
                console.log('Updating the approval in the table.')
                editRow(container, table, response.data, settings);

                button.text('');
                button.append($('<i class="fal fa-check"></i>'));
                $('.check-all').prop('checked', false);
                $('.check-all').trigger('change');
            },
            error: function(error) {
                console.log(error);
                button.text('');
                button.append($('<i class="fal fa-times"></i>'));
                let message = error.responseJSON.errors[0].message;
                displayToast(settings.toastId, message, 3000);
            }
        }).done(function () {
            // Close modal with delay, could remove this.
            // Giving user enough time to see the tick.
            setTimeout(function() {
                modal.modal('hide');
            }, 500);
        }).fail(function () {
            setTimeout(function() {
                modal.modal('hide');
            }, 500);
        });
    }

    function displayToast(toastId, message, duration)
    {
        let toast = $(toastId);
        let toastBody = toast.find(".toast-body").first();
        toastBody.text(message);
        toast.toast({ delay: duration });
        toast.toast('show');
    }
})(jQuery);




