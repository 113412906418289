(function ( $ ) {
    $.fn.service_summary = function(options = []) {
        let settings = $.extend({
            selectors: [],
            events: []
        }, options );

        initServiceSummary($(this), settings);

        function initServiceSummary(form, settings)
        {
            $('.btn-service-summary').on('click', function(e) {
                //e.preventDefault();
                console.log('get summary');
                updateServiceSummary(form);
            });

            // Need to add event listeners such that when service pricing changes we update the service summary.
            form.on('input', 'input[name="registered_at"]', function() {
                updateServiceSummary(form);
            });

            updateServiceSummary(form);
        }

        function updateServiceSummary(form)
        {
            let body = 'calculate=1&' + form.serialize();
            let url = form.attr("action");

            $.ajax({
                url: url,
                type: 'POST',
                headers: {
                    "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                },
                data: body,
                success: function(response) {
                    console.log(response);

                    // Populate recurring costs container.
                    let container = $('.costs-containers');
                    container.empty();

                    let startDate = response.billing_start_date;
                    let datePeriod = `${startDate} to ${response.billing_end_date}`;

                    // Add a warning if the billing_start_date is in the past.
                    let inPast = false;

                    if (startDate === null)
                    {
                        datePeriod = 'Please select a billing start date.';
                    }
                    else
                    {
                        // I want to compare dates ignoring time value.
                        let currentDate = new Date();
                        currentDate.setHours(0, 0, 0, 0);
                        let parsedStartDate = Date.parse(startDate);
                        console.log(`${parsedStartDate} < ${currentDate}: ${parsedStartDate < currentDate}`);
                        if (parsedStartDate < currentDate)
                        {
                            inPast = true;
                        }
                    }

                    if (inPast === true)
                    {
                        let warning = $('<div class="alert alert-danger service-summary-alert" role="alert">The billing start date selected is in the past! Continue if this is okay.</div>');
                        container.append(warning);
                    }

                    container.append(show_card_js('Billing Cycle', response.billing_cycle));
                    container.append(show_card_js('Recurring Amount', `$${response.recurring_amount}`));
                    container.append(show_card_js('Billing period', datePeriod));
                    container.append(show_card_js('Pro rata', `$${response.pro_rata_cost}`));

                    let proRataCalculation = show_card_js('Pro rata calculation ', `${response.calculations}`);
                    proRataCalculation.find('p').addClass('show-newline');

                    // Add tooltip.
                    let proRataTooltip = $('<i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top""></i>');
                    proRataTooltip.attr("title", 'The value of a day is based on the month the day belongs too.');
                    proRataTooltip.tooltip();

                    proRataCalculation.find('label').append(proRataTooltip);

                    container.append(proRataCalculation);
                },
                error: function(error) {
                    console.log(error);
                }
            });
        }

        // Function to make js equivalent of show card blade component.
        function show_card_js(labelName, value) {
            let showCard = $(`
            <div class="row ml-0">
                <div class="show-card d-block">
                    <div class="show-card-content">
                        <label class="d-block show-card-label">${labelName}</label>
                        <p class="d-block show-card-text">${value}</p>
                    </div>
                </div>
            </div>
            `)

            return showCard;
        }
    }
}( jQuery ));





