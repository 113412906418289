(function ( $ ) {

    var methods = {
        init : function(options)
        {
            var settings = getSettings(options);
            initSetPricing(settings);
            //initSetBillingCyclePrice(settings);
        },
    }

    $.fn.order_create = function(methodOrOptions) {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply( this, Array.prototype.slice.call(arguments, 1));
        }
        else if (typeof methodOrOptions === 'object' || !methodOrOptions)
        {
            // Default to "init"
            return methods.init.apply(this, arguments);
        }
        else
        {
            $.error('Method ' +  methodOrOptions + ' does not exist on jQuery.order_create');
        }
    };

    // Override default parameters with supplied options
    function getSettings(options)
    {
        var settings = $.extend({

        }, options );

        return settings;
    }

    function initSetPricing(settings)
    {
        // Depending on the contract length selected we need to fetch the appropriate billing cycles.
        $(document).on("change", '[name^="contract_length_ids["]', function(e) {
            let orderProduct = $(this).closest('.order-product');
            let contractLengthId = $(this).val();
            let productField = $(this).closest('.order-product').find('[name^="orders["][name$="][products][]"]');
            let productId = productField.val();
            let productTypeId = productField.find(':selected').data('type-id');

            let billingCyclesSelect = $(this).closest('.order-product').find('[name^="billing_cycle_ids[]"]');

            if ($(this).val() === 'legacy')
            {
                let orderEntryContainer = $(this).closest('.order-entry');
                let orderId = orderEntryContainer.find('input[name$="][order_id]"]').attr("value");
                legacyBillingCycles(settings, orderProduct, productTypeId, billingCyclesSelect, productId, contractLengthId, orderId);
            }
            else
            {
                updateBillingCycles(settings, orderProduct, productTypeId, billingCyclesSelect, productId, contractLengthId);
            }
        });

        // Depending on the billing cycle selected we need to display the appropriate price.
        $(document).on("change", '[name^="billing_cycle_ids[]"]', function(e) {
            let orderProduct = $(this).closest('.order-product');
            let billingCycleId = $(this).val();
            let contractLengthField = orderProduct.find('[name^="contract_length_ids[]"]');
            let contractLengthId = contractLengthField.val();
            let productField = orderProduct.find('[name^="orders["][name$="][products][]"]');
            let productTypeId = productField.find(':selected').data('type-id');

            //let billingCyclesSelect = $(this).closest('.order-product').find($('[name^="billing_cycle_ids[]"]'));
            updatePricing(settings, orderProduct, productTypeId, contractLengthId, billingCycleId);
        });
    }

    function updatePricing(settings, orderProduct, productTypeId, contractLengthId, billingCycleId)
    {
        let setupFeeField = orderProduct.find('[name^="setup_fees[]"]');
        let priceField = orderProduct.find('[name^="prices[]"]');
        let ettField = orderProduct.find('[name^="early_termination_types[]"]');
        let etaField = orderProduct.find('[name^="early_termination_amounts[]"]');
        let hiddenPriceIdField = orderProduct.find('[name^="product_price_ids[]"]');

        let url = `/api/v1/product_prices/?filter[product_type_id]=${productTypeId}&filter[contract_length_id]=${contractLengthId}
        &filter[billing_cycle_id]=${billingCycleId}&filter[enabled]=1`;

        $.ajax({
            url: url,
            type: 'GET',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                let productPrice = response.data[0];
                setupFeeField.text(convert_currency(productPrice.attributes.setup_fee, settings.userProfile));
                priceField.text(convert_currency(productPrice.attributes.price, settings.userProfile));
                ettField.text(productPrice.attributes.early_termination_type);
                etaField.text(productPrice.attributes.early_termination_amount === null ? 'N/A' : convert_currency(productPrice.attributes.early_termination_amount, settings.userProfile));
                hiddenPriceIdField.val(productPrice.attributes.id);
                hiddenPriceIdField.trigger('change');
            },
            error: function(error) {
                console.log(error);
            }
        });
    }

    function updateLegacyPricing(settings, orderProduct, orderItem)
    {
        let setupFeeField = orderProduct.find('[name^="setup_fees[]"]');
        let priceField = orderProduct.find('[name^="prices[]"]');
        let ettField = orderProduct.find('[name^="early_termination_types[]"]');
        let etaField = orderProduct.find('[name^="early_termination_amounts[]"]');
        let hiddenPriceIdField = orderProduct.find('[name^="product_price_ids[]"]');

        setupFeeField.text(convert_currency(orderItem.setup_fee, settings.userProfile));
        priceField.text(convert_currency(orderItem.price, settings.userProfile));
        ettField.text(orderItem.early_termination_type);
        etaField.text(orderItem.early_termination_amount === null ? 'N/A' : convert_currency(orderItem.early_termination_amount, settings.userProfile));
        hiddenPriceIdField.val('legacy');
        hiddenPriceIdField.trigger('change');
    }

    function updateBillingCycles(settings, orderProduct, productTypeId, billingCyclesSelect, productId, contractLengthId)
    {
        let url = `/api/v1/products/${productId}/billing_cycles?contract_length_id=${contractLengthId}`;
        // Using the product id get all all related billing cycles.
        $.ajax({
            url: url,
            type: 'GET',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                console.log(response);

                let billingCycles = response.map(function (item) {
                    let res = {
                        text: item.name,
                        id: item.id,
                    }

                    return res;
                });

                console.log("Billing Cycles", billingCycles);

                billingCyclesSelect.empty().select2({
                    theme: 'bootstrap4',
                    data: billingCycles
                });

                let firstBillingCycleId = response[0].id;

                updatePricing(settings, orderProduct, productTypeId, contractLengthId, firstBillingCycleId);
            },
            error: function(error) {
                console.log(error);
            }
        });
    }

    // To get the legacy price point we can make fetch the current order.
    function legacyBillingCycles(settings, orderProduct, productTypeId, billingCyclesSelect, productId, contractLengthId, orderId)
    {
        console.log('Updating price, legacy.');
        // If product price id is equal to legacy we need to get the billing cycle from the original order.
        let userProfile = settings.userProfile;

        // Do an ajax request to get the associated price.
        $.ajax({
            url: `/api/v1/orders/${orderId}?include=billingCycle,orderItems`,
            type: 'GET',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                console.log(response);
                let billingCycle = response.data.attributes.billing_cycle;

                billingCycles = [{
                    text: billingCycle.name + ' (Legacy option)',
                    id: 'legacy',
                }];

                billingCyclesSelect.empty().select2({
                    theme: 'bootstrap4',
                    data: billingCycles
                });

                // Also update the pricing with legacy values.
                let orderItem = response.data.attributes.order_items[0];
                updateLegacyPricing(settings, orderProduct, orderItem);
            },
            error: function(error) {
                console.log(error)
            }
        });
    }

    // Old function.
    function initSetBillingCyclePrice(settings)
    {
        // function to handle recurring cost of order in create part
        $(".orders-container").on("change", "select[name='product_price_ids[]']", function() {
            let productPriceId = $(this).val();
            // This selector is temporary and should be changed to be less fragile.
            let priceSpan = $(this).closest('div').next().next().find('[name="recurring_costs[]"]');

            if (productPriceId === 'legacy')
            {
                // Need to know the order to fetch the legacy price.
                let orderEntryContainer = $(this).closest('.order-entry');
                let orderId = orderEntryContainer.find('input[name$="][order_id]"]').attr("value");

                console.log('LEGACY ORDER ID: ' + orderId);
                updateLegacyPrice(orderId, priceSpan, settings);
            }
            else
            {
                updatePrice(productPriceId, priceSpan, settings);
            }
        });
    }

    // Old function.
    function updateLegacyPrice(orderId, priceSpan, settings)
    {
        console.log('Updating price, legacy.');
        // If product price id is equal to legacy we need to get pricing from the original order.
        let userProfile = settings.userProfile;

        // Do an ajax request to get the associated price.
        $.ajax({
            url: `/api/v1/orders/${orderId}?include=orderItems`,
            type: 'GET',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                let orderItem = response.data.attributes.order_items[0];
                let price = orderItem.amount;
                priceSpan.text(price);
                // To trigger event listener to update quote summary.
                priceSpan.trigger("input");
                //priceSpan.text(convert_currency(price, userProfile));
            },
            error: function(error) {
                console.log(error)
            }
        });
    }

    // Old function.
    function updatePrice(productPriceId, priceSpan, settings)
    {
        let userProfile = settings.userProfile;

        console.log('Billing_cycle changed');
        console.log(priceSpan)

        // Do an ajax request to get the associated price.
        $.ajax({
            url: '/api/v1/product_prices/' + productPriceId,
            type: 'GET',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                console.log(response);
                let price = response.data.attributes.amount;
                priceSpan.text(price);
                // To trigger event listener to update quote summary.
                priceSpan.trigger("input");
                //priceSpan.text(convert_currency(price, userProfile));
            },
            error: function(error) {
                console.log(error)
            }
        });
    }

    function convert_currency(value, userProfile)
    {
        var LocaleCurrency = require('locale-currency');
        let currencyType = LocaleCurrency.getCurrency(userProfile["locale"]);

        let valueFloat = parseFloat(value);
        let newValue = valueFloat.toLocaleString(userProfile["locale"], {style: "currency", currency: currencyType});
        return newValue;
    }
}( jQuery ));


