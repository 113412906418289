import { bs_input_dropdown_js } from './js_blade_components.js';

(function ( $ ) {
    $.fn.submit_quote = function(options = []) {
        let settings = $.extend({
            submitButtonId: '#submit-quote-btn',
            delayContractSubmitId: '#delay-contract-btn',
            modalId: '#generic-modal',
            submitFormId: '#submit-quote-form'
        }, options );

        initSubmitQuote(settings);

        function initSubmitQuote(settings)
        {
            console.log('init submit_quote.js');

            // Add event listener to catch the regular submit form.
            // Add event listener to submit the form.
            $('#submit-quote-form').on('submit', function(e) {
                e.preventDefault();
                checkWarning($(this), settings);
            });

            $('#delay-contract-btn').on('click', function(e) {
                checkWarning($(this), settings, true);
            });
        }

        function checkWarning(form, settings, defer = false)
        {
            let body = form.serialize();
            let url = `/admin/quotes/${settings.quoteId}/submit/warnings`;

            if (defer === true)
            {
                url += '?defer=1'
            }

            let usersUrl ='/api/v1/users?filter[roles.permissions.name]=approve.quotes';
            let emailGroupsUrl ='/api/v1/email_groups';

            $.when(
                $.ajax({
                    url: url,
                    type: 'POST',
                    headers: {
                        "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                    },
                    data: body,
                }),
                $.ajax({
                    url: usersUrl,
                    type: 'GET',
                    headers: {
                        "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                    },
                }),
                $.ajax({
                    url: emailGroupsUrl,
                    type: 'GET',
                    headers: {
                        "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                    },
                })
            ).then(function (response, usersResponse, emailGroupsResponse) {
                if (response[0]['canSubmit'] === true)
                {
                    if (defer === true)
                    {
                        console.log('open the defer modal');
                        displayModal(settings, response[0], usersResponse[0], emailGroupsResponse[0]);
                    }
                    else
                    {
                        // Just submit the form if it can be submitted.
                        form.unbind('submit');
                        form.submit();
                    }
                }
                else
                {
                    displayToast('#main-toast', response[0]['message'], 3000);
                }
            });
        }

        function displayModal(settings, response, usersResponse, emailGroupsResponse)
        {
            let modal = $(settings.modalId);
            let modalBody = modal.find('.modal-body');

            modalBody.empty();
            modal.find('.modal-title').text('Approval Request');

            let modalFooter = modal.find('.modal-footer');
            modalFooter.empty();

            let form = $(settings.submitFormId);

            if (response.elevated === true)
            {
                displayElevatedModalContent(form, settings, response);
            }
            else
            {
                let warning = $(`<p>You have requested to defer contract signing. This will submit the quote as well as an approval request,
                to allow accepting orders and provisioning services before the customer has finished signing the contract. Would you like to continue?</p>`);
                modalBody.append(warning);

                let users = [];

                let options = [];
                let selected = [];

                for (let i = 0; i < emailGroupsResponse.data.length; i++)
                {
                    let group =  {};

                    let groupId = 'group-' + emailGroupsResponse.data[i].attributes.id;

                    group.key = groupId;
                    group.value = emailGroupsResponse.data[i].attributes.name;
                    options.push(group);

                    if (emailGroupsResponse.data[i].attributes.type === 'default')
                    {
                        selected.push(groupId);
                    }
                }

                for (let i = 0; i < usersResponse.data.length; i++)
                {
                    let user =  {};
                    user.key = 'user-' + usersResponse.data[i].attributes.id;
                    user.value = usersResponse.data[i].attributes.name;
                    options.push(user);
                }

                let notify = bs_input_dropdown_js('Select users/groups to notify', 'notify[]', 'Select user to notify', options, true, selected);
                notify.find('select').attr('multiple', '');
                notify.find('select').attr('form', form.attr("id"));
                modalBody.append(notify);
                notify.find('select').attr('form', form.attr("id")).select2({
                    //theme: 'bootstrap4',
                    dropdownParent: modal
                });
                notify.find('[data-toggle="tooltip"]').tooltip();

                let requestDescription = bs_input_textarea_js('Reason for request', 'request_description', 'test tooltip');
                // Add a form attribute so it gets posted.
                requestDescription.find('textarea').attr('form', form.attr("id"));
                modalBody.append(requestDescription);
                requestDescription.find('[data-toggle="tooltip"]').tooltip();

                let delayContracts = $('<input type="hidden" value="1" name="delay_contracts">');
                delayContracts.attr('form', form.attr("id"));
                modalBody.append(delayContracts);
            }

            // Add modal button1
            let cancelButton = $(`<button type="button" class="btn btn-secondary waves-effect waves-themed">Cancel</button>`)
            modalFooter.append(cancelButton);

            // Hide the modal on click.
            cancelButton.on('click', function(e) {
                modal.modal('hide');
            });

            // Add modal button2
            let continueButton = $(`<button type="submit" form="${form.attr("id")}" class="btn btn-primary waves-effect waves-themed">Continue</button>`)
            modalFooter.append(continueButton);

            console.log(modal);
            modal.modal('show');
        }

        function displayElevatedModalContent(form, settings, response)
        {
            let modal = $(settings.modalId);
            let modalBody = modal.find('.modal-body');

            modal.find('.modal-title').text('Warning');

            let warning = $(`<p>You have selected to defer contract signing. This will submit the quote as well as
            allow accepting orders and provisioning services before the customer has finished signing the contract. Would you like to continue?</p>`);
            modalBody.append(warning);

            let resolutionDescription = bs_input_textarea_js('Reason for deferring contracts', 'resolution_description', 'test tooltip');
            // Add a form attribute so it gets posted.
            resolutionDescription.find('textarea').attr('form', form.attr("id"));
            modalBody.append(resolutionDescription);
            resolutionDescription.find('[data-toggle="tooltip"]').tooltip();

            let delayContracts = $('<input type="hidden" value="1" name="delay_contracts">');
            delayContracts.attr('form', form.attr("id"));
            modalBody.append(delayContracts);
        }

        function displayToast(toastId, message, duration)
        {
            let toast = $(toastId);
            let toastBody = toast.find(".toast-body").first();
            toastBody.text(message);
            toast.toast({ delay: duration });
            toast.toast('show');
        }

        function bs_input_textarea_js(labelName, name, tooltip)
        {
            let textarea = $(`
            <div class="form-group col pl-0">
                <label for="${name}">${labelName} <i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top" title="${tooltip}"></i></label>
                <textarea class='form-control' name="${name}" type='text'></textarea>
            </div>`)

            return textarea;
        }
    }
}( jQuery ));





