import { convert_datetime } from './convert_datetime.js';

(function ( $ ) {
    $.fn.audit_log = function(options) {

        let defaultUrl = `/api/v1/${options.urlModel}/audit/`;

        let settings = $.extend({
            url: defaultUrl
        }, options );

        getAuditLog($(this), settings);
    }

    // Ajax request to retrieve audits on a record.
    function getAuditLog(container, settings)
    {
        $.ajax({
            url: settings.url + settings.id,
            type: 'GET',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                console.log(response);
                renderAuditLog(container, response.data, settings.userProfile);
            },
            error: function(error) {
                console.log(error);
            }
        });
    }

    // Get audits and populate div with table of audits.
    function renderAuditLog(container, audits, userProfile)
    {
        let title = $('<h1>History</h1>');
        container.append(title);

        let table = $('<table class="table table-bordered table-striped w-100 display audit-table">');
        let tableHead = $('<thead></thead>');

        let tableHeadRow = $('<tr></tr>')

        let dateColumn = $('<th>Date</th>');
        let userColumn = $('<th>User</th>');
        let eventColumn = $('<th>Event</th>');

        tableHeadRow.append(dateColumn);
        tableHeadRow.append(userColumn);
        tableHeadRow.append(eventColumn);

        tableHead.append(tableHeadRow);

        table.append(tableHead);

        let tableBody = $('<tbody></tbody>');

        container.append(table);

        for (let i = 0; i < audits.length; i++)
        {
            renderAuditEntry(tableBody, audits[i], userProfile);
        }

        table.append(tableBody);
    }

    // Builds html element for an audit to insert into the table.
    function renderAuditEntry(container, audit, userProfile)
    {
        if (audit.event == "updated")
        {
            for (let field in audit.old_values)
            {
                if (field == 'customer_region_id')
                {
                    continue
                }

                let rows = [];

                rows.push(convert_datetime(audit.created_at, userProfile.date_format, userProfile.timezone, userProfile.locale));
                rows.push(audit.user.name);

                // Would be nice to use localisation files here.
                rows.push(`${field} changed from ${audit.old_values[field]} to ${audit.new_values[field]}.`);

                renderAuditRow(container, rows);
            }
        }
        else {
            let rows = [];

            rows.push(convert_datetime(audit.created_at, userProfile.date_format, userProfile.timezone, userProfile.locale));

            if (audit.user == null)
            {
                rows.push(null);
            }
            else
            {
                rows.push(audit.user.name);
            }

            rows.push(audit.event);

            renderAuditRow(container, rows);
        }
    }

    function renderAuditRow(container, rows)
    {
        let tableRow = $('<tr></tr');

        for (let i = 0; i < rows.length; i++)
        {
            let tableCell = $(`<td>${rows[i]}</td>`)
            tableRow.append(tableCell);
        }

        container.append(tableRow);
    }

}( jQuery ));
