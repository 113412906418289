$('#delete-modal').on('show.bs.modal', function(e)
{
    $message = $(e.relatedTarget).attr('data-message');
    $(this).find('.modal-body').text($message);
    var button = $(e.relatedTarget).closest('button');
    var form = $(e.relatedTarget).closest('form');
    //console.log(form);
    //$(this).find('.modal-footer .exec-delete').data('button', button);
    var formaction = button.attr('formaction');

    if (typeof formaction !== typeof undefined && formaction !== false) {
        form.attr('action', formaction);
    }

    $(this).find('.modal-footer .exec-delete').data('form', form);
});

$('.exec-delete').on('click', function()
{
    $(this).data('form').submit();

    $('#delete-modal').modal('hide');
    //$(this).data('button').click();
});

// Clear preview when closing modal.
$('#delete-modal').on('hidden.bs.modal', function(e)
{
    $(this).find(".modal-preview").empty();
});

$('#restore-modal').on('show.bs.modal', function(e)
{
    $message = $(e.relatedTarget).attr('data-message');
    $(this).find('.modal-body').text($message);
    var button = $(e.relatedTarget).closest('button');
    var form = $(e.relatedTarget).closest('form');
    //console.log(form);
    //$(this).find('.modal-footer .exec-delete').data('button', button);
    var formaction = button.attr('formaction');

    if (typeof formaction !== typeof undefined && formaction !== false) {
        form.attr('action', formaction);
    }

    $(this).find('.modal-footer .exec-restore').data('form', form);
});

$('.exec-restore').on('click', function()
{
    //console.log($(this).data('form'));
    $(this).data('form').submit();
    $('#restore-modal').modal('hide');
    //$(this).data('button').click();
});
